import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { TeachingSeriesPage } from "@features/teaching-series/components/teaching-series-page/teaching-series-page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { teachingSeriesIndexConfig } from "@features/teaching-series/teaching-series-index-config"
import { PageOpenGraphImage } from "@core/constants"

export const Series: FunctionComponent<PageProps<never, never>> = (props) => {
  const { header, featuredResources, metadata } =
    useIndexPageData("teaching-series")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Series,
        description: undefined
      }}
      pageOptions={{
        headerVariant: "cream",
        defaultSearchIndex: teachingSeriesIndexConfig.defaultSearchIndex,
        breadcrumbs: PageBreadcrumbs.TeachingSeries
      }}
    >
      <TeachingSeriesPage
        pageHeader={header}
        featuredList={featuredResources}
      />
    </Page>
  )
}

export default Series
