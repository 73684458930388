import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { Resource } from "../../../../types/resource"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { teachingSeriesIndexConfig } from "../../teaching-series-index-config"
import { FeaturedResources } from "../../../../components/featured-resources/featured-resources"
import { defaultSearchIndexes } from "../../../../features/search/search-constants"
import { SearchFilter } from "@features/search/components/search-filter/search-filter"

export interface TeachingSeriesPageProps {
  pageHeader: IndexPageHeader
  featuredList: Resource[]
  onTeachingSeries?: boolean
}

const indexes = defaultSearchIndexes.filter(
  (x) => x.id !== "price_asc" && x.id !== "price_desc" && x.id !== "featured"
)

const { filters, searchConfig } = teachingSeriesIndexConfig

export const TeachingSeriesPage: FunctionComponent<TeachingSeriesPageProps> = ({
  featuredList,
  pageHeader
}) => {
  return (
    <Fragment>
      <IndexHeader header={pageHeader} />
      <FeaturedResources
        shouldUpdateList
        pageKey="teaching-series"
        labelId="featured-series"
        list={featuredList}
        type="series"
      />
      <SearchResults
        filters={filters}
        indexes={indexes}
        config={searchConfig}
        variant="cards"
      />
    </Fragment>
  )
}
