import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const teachingSeriesIndexConfig: IndexPageConfig = {
  templatePath:
    "./src/features/teaching-series/templates/teaching-series-template.tsx",
  pagePath: PathPrefix.Series,
  searchConfig: { filters: `type:"Teaching Series"` },
  attributes: ["type", "time", "title", "url"],
  filters: [
    {
      attribute: "fields.freeStream",
      title: "search-filter-free"
    },
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    },
    {
      attribute: "fields.primaryScriptureReference.start.book",
      title: "search-filter-scripture"
    }
  ],
  defaultSearchIndex: "newest"
}
